import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const StyledLesne = styled.section`
    border-top: 1px solid gray;
    display: flex;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  padding-top:1rem;
  margin-top:2rem;
  justify-content: space-between;
`

const StyledLes = styled.div`
    flex: 0 0 47%;
    @media (max-width: 900px) {
        flex: 1 1 100%;
    }
`

const LesneZaloge = () => {
    return (
        <StyledLesne>
            <StyledLes>
                <StaticImage src="../images/gozdnatost.jpg" alt="Gozdnatost Slovenije" />
                <h1>Gozdnatost in pestrost gozdov</h1>
                <p>
                    Slovenija spada med najbolj gozdnate države v Evropi.
                    1.184.526 hektarjev gozdov pokriva več kot polovico površine
                    države (gozdnatost je 58,4 %).
                </p>
                <p>
                    Pretežni del slovenskih gozdov je v območju bukovih,
                    jelovo-bukovih in bukovo-hrastovih gozdov (70 %), ki imajo
                    razmeroma veliko proizvodno sposobnost.
                </p>
            </StyledLes>
            <StyledLes>
                <StaticImage src="../images/lesnazaloga.jpg" alt="Lesne zaloge slovenije" />
                <h1>Lesna zaloga Slovenije</h1>
                <p>
                    Slovenija je dežela gozdov, saj pokrivajo 58,4 % naše domovine. Po gozdnatosti smo na tretjem mestu v Evropski uniji, za Švedsko in Finsko.
                </p>
                <p>
                    Več podatkov o gozdovih slovenije lahko dobite na <a href={"http://www.zgs.si/gozdovi_slovenije/o_gozdovih_slovenije/gozdnatost_in_pestrost/index.html"}>Spletni strani zavoda za gozdove slovenije.</a>
                </p>
            </StyledLes>
        </StyledLesne>
    )
}

export default LesneZaloge