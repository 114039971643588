import React from 'react'
import { Link } from 'gatsby'

const PageTitle = ({children}) => {

    return(
        <>
        <h1 style={{color:"white"}}><Link to={"/"}>Sončna Zadruga / </Link></h1><h1 style={{color:"white", marginTop:0}}>{children}</h1> </>
    )
}

export default PageTitle